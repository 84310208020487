// colors
$viola: #3e1863;
$lilla: #ede9f3;
$red: #F2665E;
$light-coral: #f9f0ef;
$yellow: #ffce73;
$light-yellow: #fff5df;
$beige: #fff5df;
$blue: #558bcf;
$light-blue: #e8f0ff;
$gray: #8f8f8f;
$light-gray: #c9c9c9;

.bg-viola {
    background-color: $viola !important;
}

.bg-red {
    background-color: $red !important;
}

.bg-coral {
    background-color: $light-coral;
}

.color-coral {
    color: $light-coral;
}

.color-red {
    color: $red;
}

.color-light-gray {
    color: $light-gray;
}

.color-gray {
    color: $gray;
}

$container-max-widths: (
  sm: 540px,
  md: 768px,
  lg: 1024px,
  xl: 1140px
) !default;


// responsive
$tablet: 1024px;
//$mobile: 720px;
$mobile: 767px;


$header-height: 70px;
$header-height-mobile: 50px;


$spacer: 2rem !default;

$font-size-h2-title: 22px;
