#areautente {
    background-color: $light-coral;

    .page-content {
        padding: 26px 0;
    }
    .cambia-subscription {
        text-decoration: underline;
        display: inline;
        position: relative;
        cursor: pointer;
    }

    .altre-subscription {
        position: absolute;
        right: 0;
        background-color: #fff;
        color: #000;
        font-size: 19px;
        width: 200px;
        top: 39px;
        box-shadow: 0 0 5px #888;
        border-radius: 8px;
        padding: 10px;
        text-align: center;
        z-index: 2;

        a {display: block}
    }
    .outer-hero-title {
        display: flex;
        width: 100%;
        background-color: $viola;
        align-items: center;
        padding: 0px 20px;
        min-height: 180px;
        flex-direction: column;
        position: relative;
        @media screen and (max-width: $tablet) {
            min-height: 170px;
        }

    }
    .hero-title {
        position: relative;
        height: 180px;
        padding: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
    }
    .main-title {
        font-size: 40px;
        font-weight: 600;
        line-height: 50px;

        @media screen and (max-width: $tablet) {
            font-size: 25px;
            line-height: 33px;
        }
    }
    .back-to-dashboard, .cambia-cane {
        transform: translateY(-50%);
        position: absolute;
        font-size: 14px;
        z-index: 1;

        @media screen and (min-width: $tablet) {
            top: 45%;
        }
        @media screen and (max-width: $tablet) {
            bottom: 0%;
        }
    }
    .profilo-back {
        @media screen and (min-width: $tablet) {
            top: 50%;
        }
    }
    .back-to-dashboard {
        left: 55px;
        @media screen and (max-width: $tablet) {
            left: 32px;
            font-size: 12px;
        }
    }

    .cambia-cane {
        right: 55px;
    }
    .aggiungi-nuovo-cane {
        margin-bottom: 26px;
    }
    .form-dieta {
        .errorlist li {text-align: center}
    }
    .box-info-title {
        margin: 15px 0px;
    }
    //.box-info-button {
    //    padding-top: 10px;
    //}
    .box-info-spedizioni {
        @media screen and (min-width: $tablet) {
            display: flex;
            justify-content: flex-end;
            height: 100%;
            align-items: center;
        }
    }
    .altra_data {
        border: 1px solid #c9c9c9;
        padding: 14px 7px;
        font-size: 15px;
        text-align: center;
        cursor: pointer;

        &.selected {
            background-color: $viola;
            color: #fff;
            &:hover {
                background-color: $viola;
            }
        }

        &:hover {
            background-color: #c9c9c9;
        }
    }

}
