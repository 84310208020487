.hero {
    height: 100vh;
    width: 100%;
    position: relative;

    .row {
        height: 100%;
        align-items: center;
        //padding-top: 180px;
        //align-items: flex-start;

            @media screen and (max-width: 991px) {
                padding-top: 110px;
                align-items: flex-start;
            }

        .titolo-home {
            text-align: center;
            opacity: 0;

            .logo {
                width: 300px;
                padding-bottom: 30px;

                @media screen and (max-width: $tablet) {
                    width: 160px;
                    padding-bottom: 10px;
                }
            }

            .btn {
                display: inline-block;
                margin-top: 30px;
                @media screen and (max-width: $mobile) {
                    margin-top: 20px;
                }
            }

        }

        .hero-img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media screen and (max-width: $tablet) {
                top: 47px;
            }
        }
    }



    .chat-icon {
        position: fixed;
        bottom: 10px;
        right: 10px;
        width: 60px;
        z-index: 99;
    }

}

.ricetta-main {
    background-color: #FFF2E9;

    .piatto {
        padding: 12px;
        width: 75%;
    }

    .ricetta-main-icons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        transform: translateX(-59px) translateY(5%);
        @media screen and (min-width: $mobile) and (max-width: 991px) {
            transform: translateX(-40px) translateY(4%);
        }
        @media screen and (max-width: 991px) {
            height: auto;
        }
    }

    .js-ricetta-icon {
        opacity: 0;
        display: flex;
        align-items: center;
        font-weight: 600;
        font-size: 18px;
        padding: 15px 0;

        .ricetta-icon {
            width: 80px;
            padding-right: 10px;
            @media screen and (max-width: $tablet) {
                width: 70px;
            }
        }

        @media screen and (max-width: $tablet) {
            font-size: 14px;
        }

        @media screen and (max-width: $mobile) {
            max-width: 280px;
        }

    }
}


.servizi {
    background-color: $viola;

    h2, h4, p {
        color: white;
        text-align: center;
    }

    .icon {
        width: 100px;
        height: 100px;
        background-color: $red;
        border-radius: 50%;
        margin: 0 auto 20px;
    }

}


.dieta {

    .col-12 {
        padding: 0 !important;
    }

    .dieta-text {
        width: 60%;
        margin: 0 auto;
        height: 0;
        padding-bottom: 100%;
        overflow: hidden;
        position: relative;

        @media screen and (max-width: $tablet) {
            width: 85%;
        }

    }

    .dieta-image {
        background-color: $light-gray;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            padding: 4rem;
            object-fit: contain;
            position: absolute;
            top: 0;
            left: 0;
            @media screen and (max-width: $mobile) {
                padding: 1rem;
            }
        }
    }

    .dieta-text-down-icons {
        display: flex;
        align-items: center;

            .icon-small {
                width: 60px;
                height: 60px;
                background-color: $red;
                border-radius: 50%;
            }

            p {
                padding: 0 30px;
                @media screen and (max-width: $tablet) {
                    padding-right: 0;
                    padding-left: 40px;
                }
            }
    }


}


.ricette-home {

    .row {
        @media screen and (max-width: $mobile) {
            flex-direction: column-reverse;
        }
    }

    .col {
        padding: 0 !important;
    }


    .ricette-image {
        background-color: $yellow;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
        overflow: hidden;
        position: relative;

        img {
            width: 100%;
            height: 100%;
            padding: 4rem;
            object-fit: contain;
            position: absolute;
            top: 0;
            left: 0;
            @media screen and (max-width: $mobile) {
                padding: 1rem;
            }
        }
    }

    .ricette-text {
        width: 60%;
        margin: 0 auto;
        height: 0;
        padding-bottom: 100%;
        overflow: hidden;
        position: relative;

        @media screen and (max-width: $tablet) {
            width: 85%;
        }

    }


}


#le_nostre_pappe {
    .swiper-slide {
         justify-content: center;
        align-items: center;
    }
    .col-image {
        height: auto;
    }
}
.veterinario {
    background-color: $lilla;

    .swiper-container {
        width: 100%;
        height: 40%;

        @media screen and (max-width: $mobile) {
            height: 60%;
        }
    }

    .swiper-slide {
        position: relative;
        background: url("../images/bubble.png");
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center center;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1.5rem;

        @media screen and (max-width: $mobile) {
            background-position: top;
            top: 20px;
            align-items: flex-start;
            padding: 70px 30px 0;
        }


        .testo-xs {
            position: absolute;
            bottom: 0;
            left: 120px;

            @media screen and (max-width: $mobile) {
                bottom: 70px;
                left: 120px;
            }
        }

    }

    .swiper-pagination {
        .swiper-pagination-bullet-active {
            background: $viola;
        }
    }


}

.loghi {
    background-color: #f8f8f8;

    .col-4 {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 50%;
            opacity: 0.8;

        @media screen and (max-width: $mobile) {
               width: 100%;
            }
        }
    }


}

.vero-cibo {
    .container {
        padding: 190px 0px;
    }

    width: 100%;
    background: url("../images/vero-cibo-per-cani-domicilio-desktop.webp");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 25%;

    @media screen and (max-width: 768px) {
        background-color: #f4f7fe;
        background-image: none;
        .container {
            padding: 40px 0px;
        }
    }
    //@media screen and (max-width: $mobile) {

    //    //background-size: 102%;
    //    background-position: bottom left;
    //    background-repeat: no-repeat;
    //    background-size: cover;
    //}
}

.newsletter {
    background-color: $lilla;

    label, input {
        display: block;
        margin: 0 auto;
    }

    label {
        font-size: 15px;
        font-weight: 600;
    }

    input {
        width: auto;
        padding: 10px 15px;
        font-size: 20px;
        font-weight: 300;
        background-color: white;
        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
        border-radius: 7px;
        border: none;

        &:focus {
            border: 1px solid $viola;
            box-shadow: 0px 0px 8px rgba(54, 18, 104, 0.3);
        }
    }

    input[type="text"], input[type="email"] {
        width: 300px;
    }

    .privacy-container {
        margin: 15px auto 0;

        [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
            position: absolute;
            left: -9999px;
        }

        [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label {
            position: relative;
            padding-left: 25px;
            cursor: pointer;
            display: inline-block;
            font-size: 12px;
            font-weight: 400;
        }

        /* checkbox aspect */
        [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
            content: '';
            position: absolute;
            left: 2px;
            top: -3px;
            width: 15px;
            height: 15px;
            background: white;
            border-radius: 5px;
            box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
        }

        /* checked mark aspect */
        [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
            content: '✔';
            position: absolute;
            top: -4px;
            left: 5px;
            font-size: 16px;
            color: $viola;
            transition: all .2s;
        }

        /* checked mark aspect changes */
        [type="checkbox"]:not(:checked) + label:after {
            opacity: 0;
            transform: scale(0);
        }

        [type="checkbox"]:checked + label:after {
            opacity: 1;
            transform: scale(1);
        }
    }


    // errore

    label.error {
        font-size: 12px;
        font-weight: 400;
        color: $red;
        margin-top: 10px;
    }

    input.error {
        border: 1px solid $red;
    }
}

#le_nostre_pappe {
    .punto {
        padding: 5px 19px 5px 15px;
        background-color: #fff8a7;
        height: 35px;
        border-radius: 50%;
        width: 35px;
        font-weight: 600;
    }

}
#come_funziona {
    .punto {
        padding: 5px 19px 5px 15px;
        background-color: #ece9f2;
        height: 35px;
        border-radius: 50%;
        width: 35px;
        font-weight: 600;
    }
    .testo-punto {
        margin-left: 20px;
    }
}

#su_misura {
    .col-image {
        @media screen and (max-width: 991px) {
            padding-bottom: 39px;
            padding-top: 0px;
        }
    }
}

#le_nostre_pappe {
    background-color: #ffe187;
    .col-image {
        padding: 20px;
        @media screen and (max-width: $mobile) {
            padding-top: 0px;
            padding-bottom: 50px;
        }
    }
}

#nutrizionista {
    .col-text {
        height: 500px;
        @media screen and (max-width: 1140px) {
            height: 400px;
        }
        @media screen and (max-width: 991px) {
            height: auto;
            padding-bottom: 0;
        }
        @media screen and (max-width: $mobile) {
            height: auto;
            padding-bottom: 0;
        }
    }
    .col-image {
        height: 500px;
        @media screen and (max-width: 1140px) {
            height: 400px;
        }
        @media screen and (max-width: 991px) {
            height: 400px;
        }
    }

}

#dicono_di_noi {
    background-color: #f2665e1f;
}

#nutrizionista {
    .col-image {
        padding: 40px;

        @media screen and (max-width: 991px) {
            padding: 40px 40px 56px 40px;
        }
        @media screen and (max-width: $mobile) {
            padding: 27px 15px 12px;
        }
        //padding: 50px 35px 56px 35px;
    }
}
.swiper_pappe {
    .img-slider {
        height: 65%;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
            width: 68%;
        }
        @media screen and (max-width: $tablet) {
            height: 80%;
        }
    }
    .col-text-sopra {
        padding: 20px 0px 0px 0px;
    }
    .col-text-sotto {
        padding: 20px 0px 0px 0px;
    }
    .swiper-slide {
        margin-bottom: 0px!important;
    }
}
.swiper_recensioni {
  //width: 600px;
  height: 255px;
  @media screen and (max-width: 991px) {
        height: 300px;
  }
}

.bg_snack {
    @media screen and (max-width: 991px) {
        //background-image: url('/static/images/snack-naturali-per-cani-mobile.jpg');
        background-color: #e2eff7;
        padding: 45px;
    }
    //img {
    //    object-fit: cover;
    //    width: 100%;
    //    height: 100%;
    //}
    @media screen and (min-width: 992px) {
        height: 750px;
        position: relative;
        background: url('/static/images/sfondo_premietti_desktop_new.webp');
        background-position: 50% 83%;
        background-size: cover;

        .testo-snack {
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            top: 40px;
            width: 66%;
            text-align: center;
        }
    }
}

#ricette {
    .two-colums-image .col-image img {
        object-fit: contain;
    }
    .visual_container {
        img {
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        padding: 150px 130px 100px;
        position: relative;
        height: 100%;

        .visual_container-inner--txt {
            width: 50%;
            height: auto;
            padding: 20px 20px 0 0;
            bottom: 2px;
            font-size: 15px;
            line-height: 20px;
            background-color: #feefe7;
            color: #361268;
            position: absolute;
            transform: translateY(-50%);
        }

    }
    .two-colums-image .nav-link {
        color: $viola;
        border: 1px solid $viola;
        margin-right: 15px;
        border-radius: 40px;

        &.active {
            background-color: #f2665e;
            color: white;
            border: 1px solid #f2665e;
        }
    }
    @media screen and (max-width: $tablet) {
        .hero {
            height: auto;
        }
        .visual_container {
            padding: 130px 30px 0px;
            height: auto;
            img {
                object-fit: cover;
                width: 100%;
                height: 400px;
            }

            .visual_container-inner--txt {
                padding: 20px 0;
                position: relative;
                width: 100%;
                transform: initial;
            }

        }
    }
    @media screen and (max-width: $mobile) {
        .visual_container img {height: 250px;}
    }
}
