footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 130px; /* Set the fixed height of the footer here */

    background-color: $viola;
    text-align: center;
    padding: 23px;
    @media screen and (max-width: $mobile) {
        padding: 14px;
        //padding: 15px 80px 60px;
    }

    .social {
        position: absolute;
        left: 20px;
        @media screen and (max-width: $mobile) {
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
        }

        a {
            width: 20px;
            height: 20px;
            position: relative;
            display: inline-block;
            border-radius: 50%;

            &:hover {
                box-shadow: 2px 2px 0px 0px rgb(242,102,94);
            }

            img {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                object-fit: cover;
            }
        }

    }

    p {
        font-size: 13px;
        line-height: 18px;
        color: white;
        @media screen and (max-width: $mobile) {
            font-size: 10px;
            line-height: 15px;
        }
    }

    .footer-menu {
        li {list-style: none}
        a {
            color: #fff;
            margin-right: 10px;
            font-size: 13px;
            font-weight: 600;
        }
    }
}
