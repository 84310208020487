h1 {
    font-size: 45px;
    line-height: 55px;
    font-weight: 700;
    @media screen and (max-width: $mobile) {
        font-size: 30px;
        line-height: 37px;
    }
}


h2, .h2 {
    font-size: 37px;
    line-height: 42px;
    font-weight: 700;
    letter-spacing: -0.4px;

    @media screen and (max-width: $mobile) {
        font-size: 33px;
        line-height: 35px;
    }

}

h3 {
    font-size: 22px;
    font-weight: 400;

    @media screen and (max-width: $mobile) {
        font-size: 18px;
    }
}

h4 {
    font-size: 20px;
    line-height: 28px;

    @media screen and (max-width: $mobile) {
        font-size: 20px;
        line-height: 24px;
    }
}

.testo {
    font-size: 14px;
    line-height: 22px;
    font-weight: 300;

    @media screen and (max-width: $mobile) {
        font-size: 12px;
        line-height: 18px;
    }
}


.testo-xs {
    font-size: 12px;
    line-height: 16px;
    font-weight: 300;
}



.bold {
    font-weight: 700;
}

.white {
    color: white;
}

a.white:focus {
    color: white;
}

.col-text {
    font-size: 20px;
    @media screen and (max-width: $tablet) {
        font-size: 19px;
    }
}
