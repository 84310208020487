body.wizard {
    background-color: $light-coral;
}
#header_wizard {
    width: 100%;
    height: $header-height;
    background-color: white;


    .logo {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100px;
            @media screen and (max-width: $mobile) {
                width: 90px;
            }
        }
    }

    .lang-switcher {
        position: absolute;
        right: 20px;
        top: 19px;
        @media screen and (max-width: 767px) {
            top: 12px;
        }

    }

    @media screen and (max-width: $mobile) {
        height: $header-height-mobile;
    }
}

.footer_wizard {
    width: 100%;
    position: relative;
    background-color: white;
    text-align: center;
    padding: 25px 50px;
    @media screen and (max-width: $mobile) {
        display: none;
    }

    p {
        font-size: 13px;
        line-height: 18px;
        color: $viola;
    }

    .chat-icon {
        width: 60px;
        position: fixed;
        right: 10px;
        bottom: 10px;
    }

    &.step-1, &.step-2, &.step-3, &.step-4, &.step-5 {
        .img-cane {
            position: absolute;
            top: -35px;
            left: 58%;
            width: 120px;
        }
    }

    &.step-6, &.step-7, &.step-8, &.step-9 {
        .img-cane {
            position: absolute;
            top: -60px;
            left: 30%;
            width: 85px;
        }
    }

    &.step-10, &.step-11, &.step-12, &.step-13, &.step-14 {
        .img-cane {
            position: absolute;
            top: -30px;
            left: 58%;
            width: 110px;
        }
    }
}

#subscription_wizard {
    width: 100%;
    //min-height: 80%;
    //height: auto;
    background-color: $light-coral;
    position: relative;
    padding-bottom: 0px;
    @media screen and (max-width: $mobile) {
        //min-height: 100%;
    }

    [type="radio"]:checked,
    [type="radio"]:not(:checked) {
        position: absolute;
        left: -9999px;
    }

    h2 {
        font-size: 30px!important;
        line-height: 42px!important;
    }

    p {
        font-size: 16px!important;
        line-height: 20px!important;
    }

    h4 {
        @media screen and (max-width: $tablet) {
        font-size: 18px;
        }
    }

    .btn, .btn-negative {
        @media screen and (max-width: $tablet) {
            font-size: 16px;
        }
    }

    s, strike {
        @media screen and (max-width: $tablet) {
            font-size: 10px;
        }
    }
    #container_progress_bar {
        width: 100%;
        height: 10px;
        position: relative;
        background-color: $lilla;

        #progress_bar {
            width: 10%;
            height: 100%;
            background-color: $viola;
            position: absolute;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
        }

        .progress-numbers {
            position: relative;
            font-size: 14px;
            font-weight: 700;
            text-align: center;
            top: 25px;
            @media screen and (max-width: $mobile) {
                font-size: 12px;
            }
        }
    }

    #iscrizione_wizard_form {
        text-align: center;

        .step {
            padding: 80px 20px 40px;

            input[type="text"], input[type="email"] {
                width: 300px;
            }

            input[type="number"] {
                width: 200px;
            }

            // errore

            label.error {
                font-size: 12px;
                font-weight: 400;
                color: $red;
                margin-top: 10px;
            }

            input.error {
                border: 1px solid $red;
            }

        }

        .step-1 {

            input[type="number"] {
                width: 200px;
            }

            /* ------------------------- Style checkbox ---------------------- */

            [type="checkbox"]:not(:checked),
            [type="checkbox"]:checked {
                position: absolute;
                left: -9999px;
            }

            [type="checkbox"]:not(:checked) + label,
            [type="checkbox"]:checked + label {
                position: relative;
                padding-left: 25px;
                cursor: pointer;
            }

            /* checkbox aspect */
            [type="checkbox"]:not(:checked) + label:before,
            [type="checkbox"]:checked + label:before {
                content: '';
                position: absolute;
                left: 0;
                top: -3px;
                width: 15px;
                height: 15px;
                background: white;
                border-radius: 5px;
                box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
            }

            /* checked mark aspect */
            [type="checkbox"]:not(:checked) + label:after,
            [type="checkbox"]:checked + label:after {
                content: '✔';
                position: absolute;
                top: -4px;
                left: 3px;
                font-size: 16px;
                color: $viola;
                transition: all .2s;
            }

            /* checked mark aspect changes */
            [type="checkbox"]:not(:checked) + label:after {
                opacity: 0;
                transform: scale(0);
            }

            [type="checkbox"]:checked + label:after {
                opacity: 1;
                transform: scale(1);
            }

            label {
                margin: 30px 0 5px;
            }


            .privacy-container {
                margin: 30px auto 0;

                [type="checkbox"]:not(:checked),
                [type="checkbox"]:checked {
                    width: 30px;
                    display: inline-block;
                    margin-right: 5px;

                    &:focus {
                        color: $viola;
                    }
                }

                label {
                    display: inline-block;
                    font-size: 14px;
                    font-weight: 400;
                    margin-top: 0;
                }
            }


        }


        .step-2 {
            label {
                margin: 30px 0 5px;
            }

            .rimuovi-cane {
                display: inline-block;
                margin: 5px 0px 15px 0px;
                font-size: 10px;
                color: red;
                cursor: pointer;
            }

            .d-block {
                .aggiungi-cane:not(:last-child) {
                    display: none;
                }
            }

            .aggiungi-cane {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 20px;
                cursor: pointer;

                img {
                    margin-right: 10px;
                }
            }
        }

        .step-3, .step-4, .step-5, .step-10, .step-11 {
            .radio-container {
                display: flex;
                justify-content: center;

                label {
                    padding: 5px;
                    margin: 20px 0 0 0;
                }
            }

            h3 {
                margin-top: 50px;
            }

            .age {
                display: inline-flex;
                align-items: center;

                input {
                    width: 50px;
                    text-align: center;
                    margin: 20px 10px;
                }
            }
        }


        .step-6 {


        }


        .step-7 {
            .peso {
                input {
                    display: inline-block;
                    margin-top: 20px;
                }
            }
        }


        .step-8, .step-9 {
            justify-content: center;

            label {
                padding: 5px;
                margin: 0;
            }

            .corporatura .radio-container, .temperamento .radio-container {
                display: flex;
                justify-content: center;
                margin-top: 30px;

                @media screen and (max-width: $tablet) {
                    flex-direction: column;
                    margin-top: 10px;
                }

            }

            input:checked ~ .checkmark {
                svg {
                    fill: white;
                }

                h3, p {
                    color: white;
                }
            }

            .checkmark {
                height: 340px;
                max-width: 230px;
                background-color: white;
                border: 1px solid $viola;
                border-radius: 10px;
                padding: 8px;

                @media screen and (max-width: $tablet) {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 auto;
                    max-width: 60vw;
                    height: auto;
                }

                @media screen and (max-width: $mobile) {
                    max-width: 100vw;
                }

                svg {
                    width: 100%;
                    fill: $viola;
                    padding: 20px;

                    @media screen and (max-width: $tablet) {
                        flex: 0 0 40%;
                    }

                }

                &:hover {
                    background-color: $viola;
                    cursor: pointer;

                    svg {
                        fill: white;
                    }

                    h3, p {
                        color: white;
                    }
                }

                .card-text {
                    @media screen and (max-width: $tablet) {
                        text-align: left;
                        flex: 0 0 60%;
                        padding-right: 20px;
                    }

                    @media screen and (max-width: $mobile) {
                        padding-right: 0;
                    }

                }

            }
        }

        .step-10 {

            .allergie {
                max-width: 700px;
                margin: 0 auto;

                .radio-container {
                    flex-wrap: wrap;
                    margin-top: 20px;

                    label {
                        margin: 0;
                    }
                }
            }
        }

        .step-10 {
            .generic-errors {
                display: none;

                &.visible {
                    display: block;
                }
            }
        }

        .step-11 {
            padding: 80px 0px 40px;

            .piano_dieta {
                width: 100%;
                margin: 40px auto 20px auto;
            }

            .ricette {
            }

        }
        .step-12 {
            padding: 80px 0px 40px;
            input[type="number"] {
                width: 55px;
                text-align: center;
            }
        }

        .step-13 {
            #id_voglio_fattura {
                display: inline;
                width: 34px;
                box-shadow: initial;
                margin-bottom: 0;
            }

            .dettagli_checkout {
                text-align: left;

                label {
                    font-size: 15px;
                    font-weight: 300;
                }

                input {
                    width: 100%;
                    margin: 2px 0 15px;

                    &::placeholder {
                        font-size: 15px;
                        color: $viola;
                    }
                }

                .row_input {
                    margin-right: -6px;
                    margin-left: -6px;

                    .col {
                        padding-right: 6px;
                        padding-left: 6px;
                    }
                }

                textarea {
                    width: 100%;
                    height: 160px;
                    padding: 20px 15px;
                    font-size: 15px;
                    font-weight: 300;
                    background-color: white;
                    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
                    border-radius: 7px;
                    border: none;
                }

                .privacy-container {
                    label {
                        font-size: 12px;
                        display: inline-block;
                    }

                    /* Base for label styling */
                    [type="checkbox"]:not(:checked),
                    [type="checkbox"]:checked {
                        position: absolute;
                        left: -9999px;
                    }

                    [type="checkbox"]:not(:checked) + label,
                    [type="checkbox"]:checked + label {
                        position: relative;
                        padding-left: 25px;
                        cursor: pointer;
                    }

                    /* checkbox aspect */
                    [type="checkbox"]:not(:checked) + label:before,
                    [type="checkbox"]:checked + label:before {
                        content: '';
                        position: absolute;
                        left: 2px;
                        top: -3px;
                        width: 15px;
                        height: 15px;
                        background: white;
                        border-radius: 5px;
                        box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
                    }

                    /* checked mark aspect */
                    [type="checkbox"]:not(:checked) + label:after,
                    [type="checkbox"]:checked + label:after {
                        content: '✔';
                        position: absolute;
                        top: -4px;
                        left: 3px;
                        font-size: 16px;
                        color: $viola;
                        transition: all .2s;
                    }

                    /* checked mark aspect changes */
                    [type="checkbox"]:not(:checked) + label:after {
                        opacity: 0;
                        transform: scale(0);
                    }

                    [type="checkbox"]:checked + label:after {
                        opacity: 1;
                        transform: scale(1);
                    }
                }

                .scelta_premietto {
                    h4 {
                        position: relative;

                        span {
                            background-color: #f2665e;
                            color: white;
                            font-size: 12px;
                            font-weight: 500;
                            text-transform: uppercase;
                            padding: 15px 10px;
                            position: absolute;
                            left: 180px;
                            bottom: 0;
                            border-radius: 50%;
                        }
                    }


                    .radio-container {
                        display: flex;
                        margin-top: 10px;

                        @media screen and (max-width: $mobile) {
                            flex-direction: column;
                        }

                        label {
                            padding: 5px;
                            margin: 0;
                            position: relative;
                        }

                        .radiobox {
                            height: 220px;
                            max-width: 170px;
                            background-color: #fff;
                            border: 1px solid #3e1863;
                            border-radius: 20px;
                            padding: 30px;
                            position: relative;
                            display: inline-flex;
                            justify-content: center;
                            flex-direction: column;

                            img {
                                width: 80px;
                                margin: 0 auto;
                            }

                            @media screen and (max-width: $mobile) {
                                height: 100px;
                                display: flex;
                                flex-direction: row;
                                justify-content: flex-start;
                                align-items: center;
                                margin: 0 auto;
                                max-width: 100vw;
                                padding: 20px;

                                img {
                                    width: 50px;
                                    margin: 0;
                                    margin-right: 15px;
                                }
                            }


                            &:hover {
                                background-color: $viola;
                                cursor: pointer;

                                p {
                                    color: white;
                                }
                            }

                        }

                        input:checked ~ .radiobox {
                            background-color: $viola;
                        }

                        input:checked ~ .radiobox p {
                            color: white;
                        }

                    }


                }

                select {
                    width: 200px;
                    height: 50px;
                    margin: 12px 0;
                    padding: 10px 15px;
                    font-size: 15px;
                    font-weight: 300;
                    background-color: white;
                    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
                    border-radius: 7px;
                    border: none;
                }

                .riepilogo {
                    h3, h4 {
                        font-weight: 600;
                        margin-bottom: 10px;
                    }
                    h4 {
                        font-size: 15px;
                    }
                    p {
                        font-size: 14px;line-height: 18px;
                    }
                    .totale-ordine {
                        background-color: #ece9f2;
                        padding: 20px;
                    }
                    .data-consegna, .totale-cane {
                        margin-top: 18px;
                        font-size: 14px;
                        border-bottom: 1px solid #c9c9c9;
                        padding-bottom: 14px;
                    }
                    .snack-omaggio {
                        margin-top: 3px;
                        font-size: 12px;
                    }
                    .info-totale {
                        margin-top: 18px;
                    }
                }

                .riepilogo_premietto {
                    h3 {
                        font-weight: 600;
                        margin-bottom: 10px;
                        margin-top: 25px;
                        font-size: 15px;
                    }
                    p {
                        font-size: 12px;line-height: 19px;
                    }
                }

                .codice-sconto {
                    background-color: $blue;
                    border-bottom-left-radius: 20px;
                    border-bottom-right-radius: 20px;
                    padding: 30px 20px;
                    transform: translateY(-70px);
                    z-index: 0;
                    position: relative;
                    text-align: center;
                }

            }
        }

    }
}

.bottone-seleziona-ricetta {
    padding: 6px 20px;
    color: #3e1863;
    border-radius: 30px;
    border: 1px solid #3e1863;
    font-size: 13px;
    text-align: center;

    //@media screen and (max-width: $tablet) {
    //    width: 120px;
    //}

    &.selezionata {
        background-color: #3e1863;
        color: #fff;

        &::after {
            content: "SELEZIONATA";
        }

        &:hover {
            background-color: #f2665e;
            border: 1px solid #f2665e;

            &::after {
                content: "X RIMUOVI";
            }
        }
    }
    &.selected {
        background-color: #3e1863;
        color: #fff;

        &::after {
            content: "SELECTED";
        }

        &:hover {
            background-color: #f2665e;
            border: 1px solid #f2665e;

            &::after {
                content: "X REMOVE";
            }
        }
    }
}

.bottone-seleziona-premietto {
    padding: 6px 20px;
    color: #3e1863;
    border-radius: 30px;
    border: 1px solid #3e1863;
    font-size: 13px;
    text-align: center;

    &.selezionata {
        background-color: #3e1863;
        color: #fff;

        &::after {
            content: "SELEZIONATA";
        }
    }
    &.selected {
        background-color: #3e1863;
        color: #fff;

        &::after {
            content: "SELECTED";
        }
    }
}

//.descrizione-piano-dieta {
//    width: 60%;
//    margin: 31px auto;
//    line-height: 20px;
//
//    @media screen and (max-width: $tablet) {
//        width: 100%;
//        margin: 31px auto;
//        line-height: 20px;
//    }
//}
.step-1 {
    .navigation-wizard-mobile {
        @media screen and (max-width: $mobile) {
            justify-content: center;
        }
    }
}
.navigation-wizard-mobile {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 100;
    background-color: #fff;
    left: 0;
    padding: 20px;
    justify-content: center;
    //justify-content: space-between;
    @media screen and (max-width: $mobile) {
        justify-content: space-between;
    }
}

.razza {
    //justify-content: center;

    .select2, .select2-container, .select2-container--default, .select2-container--focus {
        //margin-top: 20px;
        //display: flex;
        //align-items: center;

        .select2-container--default, .select2-selection--single {
            height: 43px;
            //width: 320px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            padding: 10px 15px;
            font-size: 20px;
            font-weight: 300;
            background-color: #fff;
            box-shadow: 1px 1px 3px rgba(0, 0, 0, .25);
            border-radius: 7px;

            .select2-selection__rendered {
                color: $viola !important;

                .select2-selection__clear {
                    font-weight: 600;
                    font-size: 18px;
                    height: 20px;
                    width: 20px;
                    top: 2px;
                }
            }

            .select2-selection__arrow {
                width: 20px;
                height: 20px;
                top: 12px;
                right: 5px;
            }
        }


    }

}

.select2-custom {
    .select2, .select2-container, .select2-container--default, .select2-container--focus {
        width: 100% !important;
        min-width: 100% !important;

        .select2-container--default, .select2-selection--single {
            height: 43px;
            border: none;
            padding: 10px 4px;
            font-size: 15px;
            font-weight: 300;

            .select2-selection__rendered {
                color: $viola !important;

                .select2-selection__clear {
                    font-weight: 600;
                    font-size: 18px;
                    height: 20px;
                    width: 20px;
                    top: 2px;
                }
            }

            .select2-selection__arrow {
                width: 20px;
                height: 20px;
                top: 12px;
                right: 5px;
            }
        }

    }
}


#areautente {
    .select2, .select2-container, .select2-container--default, .select2-container--focus {
        margin-top: 11px;
        border: 1px solid #d9d9d6;
    }

    .select2-container--default, .select2-selection--single {
        height: 43px;
        box-shadow: none;
        border: 0px;
    }

    .select2-selection--single {
        border: 1px solid #d9d9d6;
    }

    .select2-container--open {
        margin-top: 0 !important;
    }
}
