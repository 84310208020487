.payment-info * {
  font-size: 16px;
  font-weight: 600;
}

.payment-info .payment-field {
  padding: 10px 20px 11px;
  background-color: #fff;
  border-radius: 7px;
  width: 100%;
}

.payment-info .payment-field + .payment-field {
  margin-top: 6px;
}

.payment-info .payment-field.focus,
.payment-info .payment-field:focus {
  color: #424770;
}

.payment-info .payment-field.invalid {
  background-color: #fa755a;
}

.payment-info .payment-field.invalid.focus {
  background-color: #f6f9fc;
}

.payment-info .payment-field.focus::-webkit-input-placeholder,
.payment-info .payment-field:focus::-webkit-input-placeholder {
  color: #cfd7df;
}

.payment-info .payment-field.focus::-moz-placeholder,
.payment-info .payment-field:focus::-moz-placeholder {
  color: #cfd7df;
}

.payment-info .payment-field.focus:-ms-input-placeholder,
.payment-info .payment-field:focus:-ms-input-placeholder {
  color: #cfd7df;
}

.payment-info input, .payment-info button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

.payment-info input {
  color: #3e1863;
}

.payment-info input::-webkit-input-placeholder {
  color: #9bacc8;
}

.payment-info input::-moz-placeholder {
  color: #9bacc8;
}

.payment-info input:-ms-input-placeholder {
  color: #9bacc8;
}

.payment-info button {
  display: block;
  width: calc(100% - 30px);
  height: 40px;
  margin: 0 15px;
  background-color: #fcd669;
  border-radius: 20px;
  color: #525f7f;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}

.payment-info button:active {
  background-color: #f5be58;
}

.payment-info .error svg .base {
  fill: #fa755a;
}

.payment-info .error svg .glyph {
  fill: #fff;
}

.payment-info .error .message {
  color: #fff;
}

.payment-info .success .icon .border {
  stroke: #fcd669;
}

.payment-info .success .icon .checkmark {
  stroke: #fff;
}

.payment-info .success .title {
  color: #fff;
}

.payment-info .success .message {
  color: #9cabc8;
}

.payment-info .success .reset path {
  fill: #fff;
}
