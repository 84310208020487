/* ------------------------------------------ Reset generale ------------------------------------------------ */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	//font: inherit;
	vertical-align: baseline;
}

div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    color: inherit;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}

a {
    color: rgba(#000, 0.7);

    &:focus,
    &:hover {
        //color: rgba(#000, 0.6);
        color: inherit;
    }
}

b, strong {
    font-weight: 600;
}

button {
	background-color: transparent;
	border: none;
    text-decoration: none;
    cursor: pointer;
}

button:hover,
button:focus {
	text-decoration: none;
	outline: 0;
    outline-offset: 0;
}

button:focus {
	text-decoration: none;
	outline: 0;
    outline-offset: 0;
}

button:active {
	text-decoration: none;
	outline: 0;
    outline-offset: 0;
}

a[href^=tel] {
	text-decoration:inherit;
	color: inherit;
}
