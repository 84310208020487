
body, html, * {
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif; font-weight: 100; font-style: normal;
    color: $viola;
    letter-spacing: -0.2px;
    line-height: initial;

    // anti-alias
    -webkit-font-smoothing: subpixel-antialiased !important;

}

html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 130px;
}

body.wizard {
    margin-bottom: 83px;
}


p {
    line-height: 24px;
}

button:disabled,
button[disabled] {
    background-color: #c9c9c9;
    &:hover {
        background-color: #c9c9c9;
    }

}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.alert {
    margin-bottom: 0;
}

.underline {
    text-decoration: underline;
}

a.white:hover {
    color: white;
}

.section-cane {
    margin-bottom: 60px!important;

    @media screen and (max-width: $tablet) {
        h2 {
            font-size: 21px;
        }
        .checkmark {
            font-size: 13px;
        }
    }
}

a.link-underline {
    text-decoration: underline;
    color: $viola;
}

.text-sm {
    font-size: 13px;
}

.btn, .btn-negative {
    border-radius: 30px;
    padding: 7px 36px;
    background-color: #f2665e;
    color: #fff;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    transition: all .4s ease;

    @media screen and (max-width: $tablet) {
        font-size: 20px;
        padding: 7px 22px;
    }


    &:hover {
        color: white;
        text-decoration: none;
        box-shadow: 2px 2px 0px 0px rgba(54,18,104,1);
    }

    &.disable {
        pointer-events: none;
        background-color: $light-coral;
    }
}

.btn-negative {
    &:hover {
        box-shadow: 2px 2px 0px 0px rgb(255,255,255);
    }
}

.btn-backward {
    font-size: 14px;
    padding:0;
    margin-right: 70px;
    color: $red;
    &:hover {
        border-bottom: 1px solid $red;
        text-decoration: none;
    }
}

#subscription_wizard #iscrizione_wizard_form .step-13 {
    .btn-backward {
        margin-right: 0px;
    }
}

.btn-annulla {
    font-size: 18px;
    /* padding: 0; */
    /* margin-right: 20px; */
    background-color: transparent;
    border: 1px solid $viola;
    color: $viola;
    cursor: pointer;
    &:hover {
        color: $viola;
    }
    @media screen and (max-width: $tablet) {
        font-size: 14px;
    }
}


.btn-small {
    padding: 6px 20px;
    background-color: $viola;
    border-radius: 30px;
    color: white;
    font-size: 13px;
    text-align: center;
    &:hover {
        background-color: $red;
    }
}

.btn-checkout {
    width: 100%;
    padding: 9px 30px;
    background-color: $viola;
    border-radius: 30px;
    color: white;
    font-size: 18px;
    text-align: center;
    //line-height: 60px;
    &:hover {
        background-color: $red;
        color: white;
    }
}

hr {
    background: $viola;
    height: 1px;
    border: 0px;
}

.alert-danger ul {
    list-style-type: none;

    li {
        font-size: 12px;
        font-weight: 400;
        color: $red;
    }
}

.errorlist {
    list-style-type: none;

    li {
        font-size: 12px;
        font-weight: 400;
        color: $red;
        //margin-top: -10px;
        margin-bottom: 10px;
    }
}

.error {
    font-size: 12px;
    font-weight: 400;
    color: $red;
    margin-top: -10px;
    margin-bottom: 10px;

}

.generic-errors, .alert-danger {
    border: 2px solid #f2665e;
    width: auto;
    text-align: center;
    margin: 0 auto 35px;
    font-size: 14px;
    line-height: 22px;
    color: $viola;
    padding: 16px 69px;
    display: inline-block;

    a {
        color: $viola;
        font-weight: 700;
    }
}

.generic-errors {
    .error {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.barra_sconto {
    background-color: $red;
    //padding: 5px 8px;


    p {
        font-size: 12px;
        line-height: 20px;
        color: white;
        text-align: center;

    }
}

label, input {
    display: block;
    margin: 0 auto;
}

label {
    font-size: 14px;
    //font-weight: 600;
}

input, select {
    width: 100%;
    padding: 9px 15px;
    font-size: 15px;
    font-weight: 300;
    background-color: #fff;
    /* box-shadow: 1px 1px 3px rgb(0 0 0 / 25%); */
    border-radius: 7px;
    border: 1px solid #D9D9D6;
    margin-top: 11px;
    margin-bottom: 11px;
    //&:focus {
    //    border: 1px solid $viola;
    //    box-shadow: 0px 0px 8px rgba(54, 18, 104, 0.3);
    //}
}

.form-group {
    margin-bottom: 20px;
}

.hero-title {
    background-color: $viola;
    padding: 30px 40px;
    text-align: center;
    color: #fff;
    font-size: 26px;
    font-weight: 700;
}

.subhero-title {
    font-size: 14px;
    font-weight: 100;
    margin-top: 15px;
}

.card {
    .card-title {
        font-weight: bold;
    }
}

.page-content {
    padding: 40px 0;
}

.box-info {
    box-shadow: 0px 0px 3px #888888;
    border-radius: 8px;
    padding: 30px;
    text-align: center;
    background-color: #fff;
    height: 100%;
    @media screen and (max-width: 991px) {
        height: auto;
    }

}
.box-info-icon {
    width: 100px;
    margin: 0px auto;
    padding: 30px 15px;
    background-color: #EEE9EC;
    border-radius: 50%;
    height: 100px;

    img {
        width: 100%;
    }
}

.box-info-title {
    font-size: $font-size-h2-title;
    //margin: 25px 0px;
    font-weight: 600;
}

.box-info-description {
    color: #232726;
    font-weight: 100;
    font-size: 16px;
    line-height: 21px;
    letter-spacing: 0px;
    .titolo {
        font-size: 12px;
    }
    .descrizione {
        font-weight: 600;
    }
    .descrizione:not(:last-child) {
        margin-bottom: 10px;
    }
}
.box-info-button {
    padding-top: 40px;
    margin-top: auto;

    a {
        padding: 8px 30px;
        font-size: 15px;
        font-weight: 100;
    }
}

.mini-card {
    border: 2px solid #3e1863;
    padding: 7px;
    border-radius: 35px;

    a {
        color: $viola;
        display: flex;
        justify-content: space-between;
        font-weight: 600;
        align-items: center;
    }
    span {
        margin-left: 13px;
    }
    i {
        padding: 12px 13px;
        background-color: #eee9ec;
        border-radius: 50%;
        font-size: 13px;
        font-weight: 100;
    }
}

//.inner-main-content {
//    background-color: $light-coral;
//}
/* ------------------------------------------------ Style radio button ------------------------------------------------ */

.vertical-radio {
    display: flex;
    align-items: center;

    input[type='radio'] {
        width: auto;
        margin-right: 5px;
    }
}
/* Hide the browser's default radio button */
//.radio-container input {
//    position: absolute;
//    opacity: 0;
//    cursor: pointer;
//}
//
//.radio-container label {
//    position: relative;
//}
//
//.radio-container label.error {
//    position: absolute;
//    //margin: 0px!important;
//    //top: 68px;
//    //left: 77px;
//    //width: 200px;
//    top: 100%;
//    left: 100%;
//    width: 100%;
//    @media screen and (max-width: $tablet) {
//        position: relative;
//        top: 0;
//        left: 0;
//    }
//}

/* Create a custom radio button */
.checkmark {
    padding: 13px 15px;
    background-color: #fff;
    color: #3e1863;
    border: 1px solid grey;
    border-radius: 19px;
    display: block;
    min-width: 140px;
    font-size: 14px;

    &:hover {
        border: 1px solid $viola;
    }
}

.premietti .checkmark.checkmark-premietti {
    padding: 0px;
    overflow: hidden;
    border: 2px solid transparent;
}

.premietti .checkmark.checkmark-premietti-selected {
    border: 2px solid #3e1863;
}

/* On mouse-over, add a grey background color */
// .radio-container:hover input ~ .checkmark {
//     border: 1px solid $viola;
// }

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
    background-color: $viola!important;
    color: white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radio-container .checkmark:after {
    background: transparent;
}


//[type="radio"]:checked,
//[type="radio"]:not(:checked) {
//    position: absolute;
//    left: -9999px;
//}
//[type="radio"]:checked + label,
//[type="radio"]:not(:checked) + label
//{
//    position: relative;
//    padding-left: 28px;
//    cursor: pointer;
//    line-height: 20px;
//    display: inline-block;
//    color: $viola;
//}
//[type="radio"]:checked + label:before,
//[type="radio"]:not(:checked) + label:before {
//    content: '';
//    position: absolute;
//    left: 0;
//    top: 0;
//    width: 18px;
//    height: 18px;
//    border: 1px solid $viola;
//    border-radius: 100%;
//    background: #fff;
//}

//.switch--horizontal {
//    [type="radio"]:checked + label:before,
//    [type="radio"]:not(:checked) + label:before {
//        display: none;
//    }
//}
//
//[type="radio"]:checked + label:after,
//[type="radio"]:not(:checked) + label:after {
//    content: '';
//    width: 12px;
//    height: 12px;
//    background: #66418a;
//    position: absolute;
//    top: 3px;
//    left: 3px;
//    border-radius: 100%;
//    -webkit-transition: all 0.2s ease;
//    transition: all 0.2s ease;
//}
//[type="radio"]:not(:checked) + label:after {
//    opacity: 0;
//    -webkit-transform: scale(0);
//    transform: scale(0);
//}
//[type="radio"]:checked + label:after {
//    opacity: 1;
//    -webkit-transform: scale(1);
//    transform: scale(1);
//}
//
//.switch--horizontal {
//    .label_1 {
//        margin-right: 10px;
//    }
//    [type=radio]:checked+label, [type=radio]:not(:checked)+label {
//        padding-left: 0;
//    }
//    [type="radio"]:checked + label:after {
//        opacity: 0;
//    }
//}

.two-colums-image {

    .col-image {
        height: 610px;
        //@media screen and (max-width: 1140px) {
        //    height: 515px;
        //}
        @media screen and (max-width: $mobile) {
            height: 335px;
        }
        @media screen and (max-width: 991px) {
            padding: 13px 15px 0px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            @media screen and (max-width: 991px) {
                border-radius: 25px;
            }
            @media screen and (max-width: $mobile) {
                border-radius: 12px;
            }
        }
    }
    .col-text {
        display: flex;
        width: 100%;
        justify-content: center;
        flex-direction: column;
        //height: 610px;
        padding: 50px;
        @media screen and (max-width: 991px) {
            height: auto;
        }
        @media screen and (max-width: $tablet) {
            padding: 50px 35px 56px 35px;
        }
        @media screen and (max-width: $mobile) {
            padding: 30px;
            //height: 400px;
        }
    }
}

.val-nut-nome {
   @media screen and (max-width: $mobile) {
       font-size: 15px;
   }
}
.val-nut-value {
   @media screen and (max-width: $mobile) {
       font-size: 15px;
   }
}

//.swiper-slide {
//    display: flex;
//    flex-direction: column;
//    justify-content: start;
//    text-align: center;
//}
//
//.swiper-button-next:after, .swiper-button-prev:after {
//    font-size: 9px!important;
//    border: 1px solid #3e1863;
//    padding: 10px 12px;
//    border-radius: 50%;
//    color: #3e1863;
//}
//
//.swiper-button-next {
//    right: 3px!important;
//}
//
//.swiper-button-prev {
//    left: 3px!important;;
//}

.jquery-loading-modal {
    z-index: 2147483640!important;
}

.select2-container--default .select2-selection--single {
    border: 1px solid #d9d9d6!important;
    padding: 6px!important;
    min-height: 43px!important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
    top: 7px!important;
}

s, strike{text-decoration:none;position:relative;}
s::before, strike::before {
    top: 50%; /*tweak this to adjust the vertical position if it's off a bit due to your font family */
    background:red; /*this is the color of the line*/
    opacity:.7;
    content: '';
    width: 110%;
    position: absolute;
    height:1.6px;
    border-radius:.1em;
    left: -5%;
    white-space:nowrap;
    display: block;
    //transform: rotate(-15deg);
}
s.straight::before, strike.straight::before{transform: rotate(0deg);left:-1%;width:102%;}
