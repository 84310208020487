.switch-field {
	display: flex;
	margin-bottom: 36px;
	overflow: hidden;
}

.switch-field input {
	position: absolute !important;
	clip: rect(0, 0, 0, 0);
	height: 1px;
	width: 1px;
	border: 0;
	overflow: hidden;
}

.switch-field label {
	background-color: #f8f0ef;
	color: rgba(0, 0, 0, 0.6);
	font-size: 14px;
	//line-height: 1;
	text-align: center;
	//padding: 8px 16px;
	margin-right: -1px;
	border: 1px solid rgba(0, 0, 0, 0.2);
	box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
	transition: all 0.1s ease-in-out;
    line-height: 18px;
    padding: 12px 20px;
    font-weight: 500;
}

.switch-field label:hover {
	cursor: pointer;
}

.switch-field input:checked + label {
	background-color: $viola;
    color: #fff;
	box-shadow: none;
}

.switch-field label:first-of-type {
	border-radius: 4px 0 0 4px;
}

.switch-field label:last-of-type {
	border-radius: 0 4px 4px 0;
}

//.switch {
//  //margin: 4rem auto;
//}
///* main styles */
//.switch {
//  width: 24rem;
//  position: relative;
//}
//.switch input {
//  position: absolute;
//  top: 0;
//  z-index: 2;
//  opacity: 0;
//  cursor: pointer;
//}
//.switch input:checked {
//  z-index: 1;
//}
//.switch input:checked + label {
//  opacity: 1;
//  cursor: default;
//}
//.switch input:not(:checked) + label:hover {
//  opacity: 0.5;
//}
//.switch label {
//  opacity: 0.33;
//  transition: opacity 0.25s ease;
//  cursor: pointer;
//}
//.switch .toggle-outside {
//  height: 100%;
//  border-radius: 2rem;
//  //padding: 0.25rem;
//  //overflow: hidden;
//  transition: 0.25s ease all;
//}
//.switch .toggle-inside {
//  border-radius: 5rem;
//  background: $viola;
//  position: absolute;
//  transition: 0.25s ease all;
//}
//.switch--horizontal {
//  //width: 350px;
//  height: 1.5rem;
//  margin: 0 auto;
//  font-size: 0;
//    text-align: center;
//  //margin-bottom: 1rem;
//}
//.switch--horizontal input {
//  height: 1.5rem;
//  width: 6rem;
//  left: 6rem;
//  margin: 0;
//}
//.switch--horizontal label {
//  font-size: 14px !important;
//    font-weight: 700 !important;
//  line-height: 1.8rem;
//  display: inline-block !important;
//  width: auto !important;
//  height: 100%;
//  //margin-left: -3rem !important;
//  text-align: center;
//}
//.switch--horizontal label:last-of-type {
//  margin-left: 5rem !important;
//}
//.switch--horizontal .toggle-outside {
//  background: #fff;
//    border: 1px solid $viola;
//  position: absolute;
//  width: 3.5rem;
//  left: 171px;
//    display: inline-flex;
//    align-items: center;
//}
//.switch--horizontal .toggle-inside {
//  height: 1.7rem;
//  width: 1.7rem;
//}
//.switch--horizontal input:checked ~ .toggle-outside .toggle-inside {
//  left: 0rem;
//}
//.switch--horizontal input ~ input:checked ~ .toggle-outside .toggle-inside {
//  left: 1.8rem;
//}
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
////.switch {
////    position: relative;
////    height: 24px;
////    width: 120px;
////    margin: 20px auto;
////    background-color: $light-gray;
////    border-radius: 30px;
////    -webkit-box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
////    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.3), 0 1px rgba(255, 255, 255, 0.1);
////}
////
////.switch-label {
////    position: relative;
////    z-index: 2;
////    float: left;
////    width: 58px;
////    line-height: 26px;
////    font-size: 11px;
////    color: $viola;
////    text-align: center;
////    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.45);
////    cursor: pointer;
////}
////
////.switch-label:active {
////    font-weight: bold;
////}
////
////.switch-label-F {
////    padding-left: 2px;
////}
////
////.switch-label-H {
////    padding-right: 2px;
////}
////
////
////.switch-input {
////    display: none!important;
////}
////
////.switch-input:checked + .switch-label {
////    font-weight: bold;
////    color: rgba(0, 0, 0, 0.65);
////    text-shadow: 0 1px rgba(255, 255, 255, 0.25);
////    -webkit-transition: 0.15s ease-out;
////    -moz-transition: 0.15s ease-out;
////    -ms-transition: 0.15s ease-out;
////    -o-transition: 0.15s ease-out;
////    transition: 0.15s ease-out;
////    -webkit-transition-property: color, text-shadow;
////    -moz-transition-property: color, text-shadow;
////    -ms-transition-property: color, text-shadow;
////    -o-transition-property: color, text-shadow;
////    transition-property: color, text-shadow;
////}
////
////.switch-input:checked + .switch-label-H ~ .switch-selection {
////    left: 60px;
////    /* Note: left: 50%; doesn't transition in WebKit */
////}
////
////.switch-selection {
////    position: absolute;
////    z-index: 1;
////    top: 2px;
////    left: 2px;
////    display: block;
////    width: 20px;
////    height: 20px;
////    border-radius: 50%;
////    background-color: $viola;
////    box-shadow: inset 0 1px rgba(255, 255, 255, 0.5), 0 0 2px rgba(0, 0, 0, 0.2);
////    -webkit-transition: left 0.15s ease-out;
////    -moz-transition: left 0.15s ease-out;
////    -ms-transition: left 0.15s ease-out;
////    -o-transition: left 0.15s ease-out;
////    transition: left 0.15s ease-out;
////}
////
////.switch-blue .switch-selection {
////    background-color: #3aa2d0;
////    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #4fc9ee), color-stop(100%, #3aa2d0));
////    background-image: -webkit-linear-gradient(top, #4fc9ee, #3aa2d0);
////    background-image: -moz-linear-gradient(top, #4fc9ee, #3aa2d0);
////    background-image: -ms-linear-gradient(top, #4fc9ee, #3aa2d0);
////    background-image: -o-linear-gradient(top, #4fc9ee, #3aa2d0);
////    background-image: linear-gradient(top, #4fc9ee, #3aa2d0);
////}
////
////.switch-yellow .switch-selection {
////    background-color: #c4bb61;
////    background-image: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #e0dd94), color-stop(100%, #c4bb61));
////    background-image: -webkit-linear-gradient(top, #e0dd94, #c4bb61);
////    background-image: -moz-linear-gradient(top, #e0dd94, #c4bb61);
////    background-image: -ms-linear-gradient(top, #e0dd94, #c4bb61);
////    background-image: -o-linear-gradient(top, #e0dd94, #c4bb61);
////    background-image: linear-gradient(top, #e0dd94, #c4bb61);
////}
