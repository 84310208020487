#subscription_wizard #iscrizione_wizard_form .step-11 .ricette-checkbox-container h3 {
    @media screen and (max-width: $tablet) {
        margin-top: 10px;
    }
}

.ricette-checkbox-container {
    .checkmark {
        @media screen and (max-width: $tablet) {
            min-width: 260px;
        }
    }
}

.premietti {
    .small-container {
        max-width: 600px;
        margin: 0px auto
    }

    .ricette-checkbox-container {
        justify-content: initial;
        display: block;
        overflow: hidden;

        .checkmark img {
            border-radius:  0 19px 19px 0;
            object-fit: cover;
            height: 100%;
            @media screen and (max-width: 991px) {
                height: 170px;
            }
        }
    }
    .ricette-checkbox-container label {
        width: 100%;
        margin: 0px 0px 20px 0px;
    }
    .ricette-checkbox-container .checkmark {
        flex-direction: row-reverse;
        justify-content: flex-start;
        align-items: initial;
    }
    .ricette-checkbox-container .checkmark.checkmark-no-grazie {
        width: 70px;
        background: transparent;
        border: 1px solid #3e1863;
        margin: 0px auto;

        input:checked~.checkmark {
            box-shadow: none!important;
        }
    }
    .ricette-checkbox-container input:checked~.checkmark-no-grazie {
        background-color: #3e1863;
        color: #fff;
    }

    .ricette-checkbox-container .img-container {
        width: 173px;
        @media screen and (max-width: 991px) {
            width: 130px;
        }
    }
    .ricette-checkbox-container .checkmark-no-grazie .info-container {
        @media screen and (max-width: $mobile) {
            text-align: center;
            margin-left: 0px;
        }
    }
    .ricette-checkbox-container .info-container {
        flex-grow: 1;
        @media screen and (max-width: $mobile) {
            text-align: left;
            margin-left: 20px;
        }

        input[type='number'] {
            width: 64px;
            text-align: center;
            @media screen and (max-width: $mobile) {
                margin: 10px 0px;
                padding: 2px 15px;
            }
        }
        h3 {
            font-size: 18px;
        }
    }
    .bottone-seleziona-ricetta {
        width: 300px;
        @media screen and (max-width: 991px) {
            padding: 3px 20px;
            font-size: 12px;
            width: 120px;
        }
        &.selezionata {
            &::after {
                content: "SELEZIONATO";
            }
        }
        &.selected {
            &::after {
                content: "SELECTED";
            }
        }
    }
    input:checked~.checkmark {
        box-shadow: none!important;
    }
    .btn-no-grazie {
        padding: 4px 0px;
        @media screen and (min-width: $mobile) {
            padding: 0px;
        }
    }
}

.ricette-checkbox-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;

    @media screen and (max-width: $tablet) {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: scroll;
        justify-content: initial;
    }

    label {
        margin: 10px;
        width: 20%;
        @media screen and (max-width: $tablet) {
            width: 40%;
        }
        @media screen and (max-width: $mobile) {
            width: 70%;
        }
    }

    [type="checkbox"]:not(:checked),
    [type="checkbox"]:checked, [type="radio"]:not(:checked),
    [type="radio"]:checked {
        position: absolute;
        left: -9999px;
    }


    .checkmark {
        padding: 0px;
        border-radius: 20px;
        //width: 25%;
        //height: 350px;
        //opacity: .5;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        border: 2px solid transparent;
        text-align: center;
        //@media screen and (max-width: $tablet) {
        //    min-width: 500px;
        //    height: 250px;
        //
        //    .card-text {
        //        text-align: left;
        //    }
        //}
        //@media screen and (max-width: $mobile) {
        //    min-width: 350px;
        //    height: 200px;
        //}

        .check-circle {
            display: none;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background-color: #ffffff;
            border: 1px solid $viola;
            position: absolute;
            top: 13px;
            left: 13px;
            z-index: 1;

            &::after {
                content: '✔';
                position: absolute;
                top: -3px;
                left: 3px;
                font-size: 25px;
                color: $viola;
                transition: all .2s;
                display: none;
            }
        }


        img {
            width: 100%;

            //@media screen and (max-width: $mobile) {
            //    width: 80px;
            //}
        }
    }
}

.ricette-checkbox-container input:checked ~ .checkmark {
    background-color: white;
    color: $viola;
    border: 2px solid $viola;
    box-shadow: 0 0 18px -7px #3e1863;
    opacity: 1;
}

.ricette-checkbox-container input:checked ~ .checkmark .check-circle::after {
    display: block;
}

.piano-dieta-radio {
    justify-content: space-between!important;
    label {
        width: 40%!important;
    }
}

#areautente {
    //.ricette-checkbox-container {
    //
    //    //justify-content: center;
    //
    //    label {
    //        width: 15%;
    //    }
    //}
}
