@media screen and (max-width: 1023px) {
    .zsiqwidget {
        display: none;
    }
}

.zsiq_theme1 div.zsiq_cnt {
    display: none;
}

#zsiqbtn {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 84px;
    height: 84px;
}

.wizard {
    #zsiqbtn {
        bottom: 75px;
    }
    .whatsapp-widget--container {
        bottom: 88px;
    }
}

#siqbtndiv {
    width: auto;
    background: transparent;
}

#siqbtndiv:after {
    display: none;
}

#siqbtndiv:before {
    border: none;
}

#siqbtndiv span, #siqbtndiv em {
    display: none;
}

.zsiq_float12 .siqico-chat:before {
    color: #361168;
}

@media screen and (min-width: 1024px) {
    .whatsapp-widget--container {
        display: none;
    }
}

.whatsapp-widget--container {
    opacity: 1;
    visibility: visible;
    z-index: 2147483639;
    position: fixed;
    bottom: 18px;
    width: 58px;
    height: 58px;
    max-width: 100%;
    max-height: calc(100% - 0px);
    min-height: 0px;
    min-width: 0px;
    background-color: transparent;
    border: 0px;
    overflow: hidden;
    right: 18px;
    transition: none 0s ease 0s !important;
}

.whatsapp-widget {
    position: absolute;
    bottom: 0px;
    z-index: 2;
    will-change: transform, opacity;
    backface-visibility: hidden;
    right: 0px;
}

.whatsapp-widget .whatsapp-widget_wrapper .whatsapp-widget_wrapper--icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.whatsapp-widget .whatsapp-widget_wrapper .whatsapp-widget_wrapper--icon img {
    width: 58px;
    height: 58px;
}
