body.header-compact {
    header {
        background-color: white;
        box-shadow: 0px 2px 5px -2px rgba(0, 0, 0, 0.25);

        .logo {
            display: block;

            @media screen and (max-width: $tablet) {
                width: 90px;
            }
        }
    }
    .main-content {
        padding-top: 90px;
    }
}

header {
    width: 100%;
    height: 90px;
    background-color: transparent;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    transition: all ease .4s;

    .logo {
        width: 100px;
        display: none;
    }

    .navbar {
        width: 100%;
        padding: 15px 30px;
        display: flex;
        position: relative;

        .navbar-left {
            width: 100%;
            flex: 0 0 33.3%;
            display: flex;
            align-items: center;

            @media screen and (max-width: $tablet) {
                display: none;
            }
        }

        .navbar-center {
            width: 100%;
            flex: 0 0 33.3%;
            display: flex;
            align-items: center;
            justify-content: center;

            //.logo {
            //    width: 120px;
            //    transition: all ease .4s;
            //    opacity: 0;
            //}

            @media screen and (max-width: $tablet) {
                flex: 0 0 100%;
            }

            //@media screen and (max-width: $mobile) {
            //    justify-content: flex-start;
            //    margin-left: 70px;
            //
            //}
        }

        .navbar-right {
            width: 100%;
            flex: 0 0 33.3%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            @media screen and (max-width: $tablet) {
                display: none;
            }
        }

        .nav-link {
            font-size: 16px;
            color: $viola;
            font-weight: 600;
            padding: 0 10px;
            &:hover{
                text-decoration: none;
                color: $red;
            }
        }




    }


}




// menu mobile


.navbar-toggler {
    position: fixed;
    top: 41px;
    left: 10px;
    display: none;
    z-index: 9999;

    @media screen and (max-width: $tablet) {
        display: block;

        .close {
            display: none;
        }
    }
}

.calcola-dieta {
    position: fixed;
    top: 41px;
    right: 10px;
    display: none;
    z-index: 999;

    @media screen and (max-width: $tablet) {
        display: block;
    }
}

.navbar-mobile {
    width: 100%;
    height: 100%;
    background-color: $light-coral;
    position: fixed;
    //top: calc( 70px + 30px);
    top: 0px;
    transform: translateY(-100%);
    display: none;
    z-index: 0;
    //transition: all ease .4s;

    .logo {
            width: 100px;
    }

    .nav-link {
        width: 100%;
        font-size: 18px;
        color: $viola;
        font-weight: 600;
        display: block;
        text-align: center;
        padding: 25px 0;
        border-bottom: 1px solid #3e186330;
        //&:first-of-type {
        //    border-top: 1px solid $viola;
        //}
        &:last-of-type {
            border: none;
        }
        &:hover{
            text-decoration: none;
        }
    }

    &.extended {
        transform: translateY(0);
        display: block;
        z-index: 999;
    }
}
