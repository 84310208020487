.modal-content {
    border-radius: 20px!important;
    padding: 40px;
}

.modal-header {
    display: none!important;
}

.modal-body {
    text-align: center;
    padding: 0px;
}

.testo-modal {
    color: #1C2821;
    font-size: 14px;
}

.modal-footer {
    border-top: 0;
    justify-content: center;
}
